import { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import styled, { css } from 'styled-components';

import { useAmplitude, useGTM } from '@styleshare/analytics';
import { initSshareLayer } from '@styleshare/analytics/google-tag-manager/utils/initSshareLayer';
import { env } from '~core';

import { BaseMeta } from './BaseMeta';
import { GNB_DESKTOP_HEIGHT, GNB_MOBILE_HEIGHT } from '../constants/gnb';
import type { LayoutModel } from '../models';
import { staticURL } from '../utils';

interface Props extends LayoutModel {
  children?: React.ReactNode;
}

export const WebLayout = ({
  title,
  imageId,
  disabledGnb,
  children,
  className,
}: Props) => {
  const { stage, beta, production } = env;

  /**
   * FIXME:
   * GTM에 종속적인 분석툴 구조를 변경해야함.
   * -> window.sshareLayer를 해결 또는 제거
   */

  useEffect(() => {
    initSshareLayer();
  }, []);

  useGTM({ enabled: production, gtmId: 'GTM-MZB7KBM' }); // www.styleshare.kr

  useAmplitude({
    amplitudeId: 'a28dccc1329d62d4850491ad07079774',
    enabled: production,
  });
  useAmplitude({
    amplitudeId: 'b21cecdae113bc55c94788e79acdc2b0',
    enabled: stage || beta,
  });

  return (
    <>
      <Head>
        <BaseMeta />
        <title key="title">
          {title ? `${title} - 스타일쉐어` : '스타일쉐어'}
        </title>
        {imageId && (
          <>
            <meta content={staticURL(imageId, 500, 500)} property="og:image" />
            <meta content={staticURL(imageId, 500, 500)} name="twitter:image" />
          </>
        )}
        {/* Facebook Pixel */}
        {production && (
          <Script
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '596770320465923');`,
            }}
            strategy="lazyOnload"
            type="text/javascript"
          />
        )}
        {/* Naver Analytics */}
        {production && (
          <Script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s){var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s);j.addEventListener('load', function(){ if (window.wcs) {
            if (!window.wcs_add) window.wcs_add={}; window.wcs_add['wa']='s_1ff7c42d8cf6';
            if (!window._nasa) window._nasa={}; wcs.inflow();wcs_do(window._nasa);
            }});j.async=true;j.src='https://wcs.naver.net/wcslog.js';
            f.parentNode.insertBefore(j,f);
            })(window,document,'script');`,
            }}
            strategy="afterInteractive"
            type="text/javascript"
          />
        )}
      </Head>
      <MainWrapper $disabledGnb={!!disabledGnb} className={className}>
        {children}
      </MainWrapper>
    </>
  );
};

const MainWrapper = styled.main<{ $disabledGnb: boolean }>`
  ${({ $disabledGnb }) =>
    !$disabledGnb &&
    css`
      margin-top: ${GNB_MOBILE_HEIGHT}px;

      ${({ theme }) => theme.mediaQuery.lg} {
        margin-top: ${GNB_DESKTOP_HEIGHT}px;
      }
    `};
`;
