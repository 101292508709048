import styled from 'styled-components';

import { AdaptiveLink } from '~modules/@shared/components';

import adiosImage from '../assets/adios_styleshare.png';

export const HeroImageSection = () => {
  return (
    <StyledHero>
      <AdaptiveLink
        appHref={`stsh://web/${Buffer.from(
          'https://www.styleshare.kr/notice/adios',
        ).toString('base64')}`}
        href={'https://www.styleshare.kr/notice/adios'}
        target="_blank"
      >
        <img {...adiosImage} alt="스타일쉐어 서비스 종료 안내" />
      </AdaptiveLink>
    </StyledHero>
  );
};

const StyledHero = styled.div`
  width: 100%;
  > img {
    pointer-events: none;
  }
  background-color: black;
`;
