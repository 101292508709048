import React from 'react';

export const BaseMeta = () => {
  return (
    <>
      {/* Android */}
      <meta content="StyleShare" property="al:android:app_name" />
      <meta content="com.styleshare.android" property="al:android:package" />
      {/* iOS */}
      <meta content="StyleShare" property="al:ios:app_name" />
      <meta content="458165974" property="al:ios:app_store_id" />
      <meta content="StyleShare" name="twitter:app:name:iphone" />
      <meta content="StyleShare" name="twitter:app:name:ipad" />
      <meta content="StyleShare" name="twitter:app:name:googleplay" />
      <meta content="com.styleshare.android" name="twitter:app:id:googleplay" />
      <meta content="458165974" name="twitter:app:id:iphone" />
      <meta content="458165974" name="twitter:app:id:ipad" />

      {/* Metadata */}
      <meta content="190144500999654" property="fb:app_id" />
      {/* Site Verifications */}
      <meta
        content="nYwHu7ayqngjguIxMsGLUUR7CRtXOJP-IRFdf2mSS-Q"
        name="google-site-verification"
      />
      <meta
        content="db09d01d414afba34f5bf6a0a8244324aa5d798a"
        name="naver-site-verification"
      />
      {/* twitter */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Organization',
          'contactPoint': [
            {
              '@type': 'ContactPoint',
              'areaServed': 'KR',
              'availableLanguage': 'Korean',
              'contactType': 'customer service',
              'telephone': '+82-1833-8879',
            },
          ],
          'logo':
            'https://usercontents-d.styleshare.io/images/40193603/256x256',
          'name': '스타일쉐어',
          'sameAs': [
            'https://www.facebook.com/StyleShareApp',
            'https://www.instagram.com/styleshare_kr',
            'https://twitter.com/StyleShare_twt',
            'https://www.youtube.com/c/StyleShareTV',
          ],
        })}
      </script>
    </>
  );
};
