/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { AdaptiveLink } from '~modules/@shared/components';
import { useDeviceDetect } from '~modules/@shared/hooks';
import { useMeProfileService } from '~modules/@shared/services';

import icArchive from '../assets/ic-archive.svg';
import icCs from '../assets/ic-cs.svg';
import icNotice from '../assets/ic-notice.svg';
import icOrderList from '../assets/ic-order-list.svg';
import icRefund from '../assets/ic-refund.svg';
import icSetting from '../assets/ic-setting.svg';

type MenuData = {
  appHref?: string;
  href?: string;
  onClick?: () => void;
  src: string;
  sub: string;
  title: string;
};
const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const MenuSection = () => {
  const { native } = useDeviceDetect();
  const { data } = useMeProfileService();

  const menus = useMemo(() => {
    const menuData: MenuData[] = [
      {
        appHref: `stsh://web/${Buffer.from(`${baseURL}/notice/108`).toString(
          'base64',
        )}`,
        href: `${baseURL}/notice/108`,
        src: icNotice.src,
        sub: '서비스 안내',
        title: '공지사항',
      },
      {
        appHref: `stsh://order/history`,
        href: `${baseURL}/login?return_url=https%3A%2F%2Fwww.styleshare.kr%2Forder%2Fhistory`,
        src: icOrderList.src,
        sub: '배송•반품•취소',
        title: '주문내역',
      },
      {
        appHref: `stsh://web/${Buffer.from(
          `${baseURL}/moving-to-mss/my-points`,
        ).toString('base64')}?navigable=false&browsable=false`,
        href: `${baseURL}/moving-to-mss/my-points`,
        src: icRefund.src,
        sub: '환불 계좌 관리',
        title: '현금성\n단추 환급',
      },
      {
        appHref: 'mailto:help@styleshare.kr',
        href: 'mailto:help@styleshare.kr',
        src: icCs.src,
        sub: '1:1 문의하기 - help@styleshare.kr',
        title: 'CS 문의',
      },
      // {
      //   appHref: `stsh://web/${Buffer.from('${baseURL}/archives').toString(
      //     'base64',
      //   )}?browsable=false`,
      //   href: `${baseURL}/archives`,
      //   src: icArchive.src,
      //   sub: '내 스타일 다운로드',
      //   title: '아카이빙',
      // },
    ];

    if (data?.id) {
      menuData.push({
        onClick: () => {
          if (native) {
            window.location.href = `stsh://settings`;
          } else {
            if (
              window.confirm(
                '내 정보 설정은 앱에서 가능합니다. 로그아웃 하시겠습니까?',
              )
            ) {
              window.location.href = `${baseURL}/logout`;
            }
          }
        },
        src: icSetting.src,
        sub: '계정설정•로그아웃',
        title: '설정',
      });
    }
    return menuData;
  }, [data?.id, native]);
  return (
    <Wrapper>
      <Menu>
        {menus.map(({ title, sub, src, href, appHref, onClick }) => (
          <MenuItem key={title}>
            <AdaptiveLink
              appHref={appHref}
              href={href}
              target="_blank"
              onClick={onClick}
            >
              <p className="title">{title}</p>
              <p className="sub">{sub}</p>
              <img
                alt={title}
                css={css`
                  position: absolute;
                  right: 12px;
                  bottom: 12px;
                `}
                src={src}
              />
            </AdaptiveLink>
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
`;

const Menu = styled.ul`
  display: grid;
  ${({ theme }) => theme.grid.cols_2};

  gap: 8px;
`;
const MenuItem = styled.li`
  a {
    position: relative;
    display: block;
    aspect-ratio: 167 / 132;
    padding: 16px;
    background-color: white;
    border-radius: 12px;
    .title {
      margin-bottom: 4px;
      color: #131518;
      font-weight: bold;
      font-size: 24px;
      white-space: pre-wrap;
    }
    .sub {
      color: #757d86;
      font-size: 13px;
    }
  }
  &:nth-child(5) {
    grid-column: span 2;
    a {
      aspect-ratio: 167 / 66 !important;
    }
  }
`;
