import styled, { css } from 'styled-components';

export const HeroSection = () => {
  return (
    <StyledHero>
      <StyledAdiosStyleShare>Adios, Styleshare</StyledAdiosStyleShare>
      <div
        css={`
          padding-bottom: 80px;
        `}
      >
        <p
          css={css`
            font-weight: bold;
            font-size: 38px;
            ${Colord}
          `}
        >
          스타일쉐어
        </p>
        <p
          css={`
            color: white;
            font-weight: bold;
            font-size: 38px;
          `}
        >
          서비스 종료 안내
        </p>
      </div>
      <div
        css={css`
          padding-bottom: 46px;
        `}
      >
        <p
          css={css`
            margin-bottom: 8px;
            font-weight: bold;
            font-size: 18px;
            white-space: pre;
            ${Colord}
          `}
        >
          {'스타일쉐어 서비스가\n2022년 12월 1일부터 종료됩니다.'}
        </p>
        <p
          css={css`
            color: white;
            font-size: 13px;
            white-space: pre;
          `}
        >
          {'서비스 이용에 불편함이 없도록\n종료 공지사항을 확인하세요.'}
        </p>
      </div>
    </StyledHero>
  );
};

const Colord = css`
  color: transparent;
  background-image: linear-gradient(to right, #8a7dff 3%, #c0bbff);
  -webkit-background-clip: text;
  background-clip: text;
`;

const StyledHero = styled.div`
  width: 100%;
  padding: 0 18px;
  /* padding-top: max(44px, constant(safe-area-inset-top));
  padding-top: max(44px, env(safe-area-inset-top)); */
  background-color: black;
`;
const StyledAdiosStyleShare = styled.p`
  padding: 8px 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.44px;
`;
