import React from 'react';
import Head from 'next/head';

import { useGTM } from '@styleshare/analytics/google-tag-manager';
import { WebviewGlobalStyle } from '@styleshare/styled/globalStyles';
import { env } from '~core';

import { BaseMeta } from './BaseMeta';
import { LayoutModel } from '../models';

interface Props extends LayoutModel {
  children?: React.ReactNode;
}

export const WebViewLayout = ({ title, children }: Props) => {
  const { production } = env;

  useGTM({ enabled: production, gtmId: 'GTM-KVDDBJW' }); // webview.styleshare.kr

  return (
    <>
      <Head>
        <BaseMeta />
        <title>{title || '스타일쉐어'}</title>
        <meta
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
          name="viewport"
        />
      </Head>
      {!env.isServer && <WebviewGlobalStyle />}
      {children}
    </>
  );
};
