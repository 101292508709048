import React from 'react';
import Head from 'next/head';
import styled from 'styled-components';

import { BaseMeta } from './BaseMeta';
import type { LayoutModel } from '../models';

interface Props extends LayoutModel {
  children?: React.ReactNode;
}

export const ProseLayout = ({ children, title, ...props }: Props) => {
  return (
    <>
      <Head>
        <BaseMeta />
        <title>{title ? `${title} - 스타일쉐어` : '스타일쉐어'}</title>
      </Head>
      <StyledMain {...props}>{children}</StyledMain>
    </>
  );
};

const StyledMain = styled.main`
  max-width: ${({ theme }) => theme.breakpoint[2]}px;
  margin: 0 auto;
  padding: 48px;
  color: ${({ theme }) => theme.color.gray100};
  line-height: 1.4;

  a {
    text-decoration: underline;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul,
  ol {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 28px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  & > ul > li p {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  & > ul > li > *:first-child {
    margin-top: 20px;
  }

  & > ul > li > *:last-child {
    margin-bottom: 20px;
  }

  & > ol > li > *:first-child {
    margin-top: 20px;
  }

  & > ol > li > *:last-child {
    margin-bottom: 20px;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.color.gray70};
    font-weight: bold;
    line-height: 1.2;
  }

  table {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: ${({ theme }) => theme.fontSize.s14};
    text-align: left;
    table-layout: auto;
    border-collapse: collapse;
  }

  thead {
    font-weight: bold;
  }

  thead th {
    padding: 12px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid ${({ theme }) => theme.color.gray60};
  }

  tbody td {
    padding: 16px;
    vertical-align: top;
    border: 1px solid ${({ theme }) => theme.color.gray60};
  }

  h1 {
    margin-bottom: 32px;
    padding-bottom: 0.4em;
    font-size: ${({ theme }) => theme.fontSize.s28};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray20};
  }

  h2 {
    margin-top: 48px;
    margin-bottom: 24px;
    padding-bottom: 0.4em;
    font-size: ${({ theme }) => theme.fontSize.s24};
    border-bottom: 1px solid ${({ theme }) => theme.color.gray20};
  }

  h3 {
    margin-top: 32px;
    margin-bottom: 12px;
    font-size: ${({ theme }) => theme.fontSize.s20};
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: ${({ theme }) => theme.fontSize.s18};
  }

  .lead {
    font-weight: bold;
    font-size: 1.25em;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
