import React from 'react';

import { WebLayout } from './WebLayout';
import { WebViewLayout } from './WebViewLayout';
import { useDeviceDetect } from '../hooks';
import type { LayoutModel } from '../models';

interface Props extends LayoutModel {
  children?: React.ReactNode;
}

export const Layout = ({ children, ...props }: Props) => {
  const { native } = useDeviceDetect();

  const LayoutComponent = native ? WebViewLayout : WebLayout;

  return <LayoutComponent {...props}>{children}</LayoutComponent>;
};
