import { SimpleFooter } from '~modules/@shared/components';

import { HeroSection, MenuSection } from '../components';
import { HeroImageSection } from '../components/HeroImageSection';

const HomeFeedShortcutTemplate = () => {
  return (
    <>
      {/* <HeroSection /> */}
      <HeroImageSection />
      <MenuSection />
      <SimpleFooter />
    </>
  );
};

export default HomeFeedShortcutTemplate;
