/* stylelint-disable selector-id-pattern */
import { ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Layout } from '~modules/@shared/layouts';

interface HomeFeedShortcutLayoutProps {
  children: ReactNode;
}

export const HomeFeedShortcutLayout = ({
  children,
}: HomeFeedShortcutLayoutProps) => {
  return (
    <Layout
      // css={`
      //   width: 100%;
      //   padding-top: max(0px, constant(safe-area-inset-top));
      //   padding-top: max(0px, env(safe-area-inset-top));
      //   padding-bottom: max(0px, constant(safe-area-inset-bottom));
      //   padding-bottom: max(0px, env(safe-area-inset-bottom));
      //   -webkit-overflow-scrolling: touch;
      // `}
      disabledGnb
    >
      {/* <IOSWebviewGuard /> */}
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </Layout>
  );
};
HomeFeedShortcutLayout.displayName = 'HomeFeedShortcutLayout';

const IOSWebviewGuard = createGlobalStyle`
  html, body {
    position: fixed;
    overflow: hidden;
  }
  /* stylelint-disable-next-line selector-id-pattern */
  #__next {
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }
`;
const MaxWidthContainer = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.breakpoint[2]}px;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  background-color: #f3f3f3;
`;
